"use client";

import Link from "next/link";
import styles from './SignInForm.module.css';

import {
  emailSignIn,
  pswSignIn,
  signUserOut,
  googleSignIn,
  githubSignIn
} from "@/app/server/actions/auth";
import { useSearchParams } from "next/navigation";


const SignInForm = ({ signOut = false }) => {
  const searchParams = useSearchParams();
  const showForm = searchParams.get('showForm') === 'true';
  if (signOut) {
    return (
      <div className={styles.container}>

        <button type="button" onClick={() => signUserOut()} className={styles.signOut}>
          Sign Out
        </button>

      </div>
    );
  }
  return (
    <div className={styles.container}>
      {showForm && <>
        <h2>Sign in with Credentials</h2>
        <div>
          <form action={pswSignIn} className={styles.signInForm}>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              placeholder="Email"
              required
            />
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              autoComplete="current-password"
              required
            />
            <button type="submit">Sign in</button>
          </form>
          <div>
            Not a member?&nbsp;<Link href="/auth/register" className={styles.link}>Register here</Link>
          </div>
        </div>
      </>}
      <h2>Sign in with Third Party Credentials</h2>
      <div className={styles.signInButtonContainer}>
        <form action={googleSignIn} className={styles.googleForm}>
          <button type="submit">With Google</button>
        </form>
        <form action={githubSignIn} className={styles.githubForm}>
          <button type="submit">With GitHub</button>
        </form>
        <div className={styles.signSpilt}></div>
        <form action={emailSignIn} className={styles.sendgridForm}>
          <input type="text" name="email" placeholder="Email" required={true} />
          <button type="submit">With email magic link</button>
        </form>
      </div>
    </div>
  );
};

export default SignInForm;
